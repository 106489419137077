@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;500;600;700&display=swap');



body{
  font-size: 16px;
  font-family: 'Quicksand', sans-serif;
  line-height: 1.5;
  color: #333;
  font-weight: 500;
  transition: ease-in-out .5s all;
}
select, option {
  -webkit-appearance: none;
}
input,
select,
option,
button {
    background-color: #ffffff;
    border-radius: 1vh;
    border-color: transparent;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px white inset !important;
 box-shadow: 0 0 0 30px white inset !important;
}
a{
  text-decoration:none!important;
}
body ul{
  padding: 0;
}
body.dark-mode{
  background: radial-gradient(circle, rgba(17,17,71,1) 0%, rgba(11,12,69,1) 22%, rgba(11,36,55,1) 100%)!important;
}
body.dark-mode .link-mode.active{
    color: #fff;
}


body.dark-mode .bt-menu svg{
  fill:#fff;
}
body.dark-mode .drep-mode{
  color: #fff;
}
body.fix .menu-fixed{
  background: #fff;
  box-shadow: 1px 2px 6px 1px #bbaeae;
}
body.dark-mode.fix .menu-fixed{
  box-shadow: 1px 2px 6px 1px #292828;
  background: radial-gradient(circle, rgba(17,17,71,1) 0%, rgba(11,12,69,1) 22%, rgba(11,36,55,1) 100%);
}
body img {
  height: auto;
  max-width: 100%;
}
.main{
  margin-top: 72px;
}
@media screen and (max-width:1025px){
  body h3, body h2{
    font-size: 30px !important;
  }
  body p{
    font-size: 16px !important;
  }
}
@media screen and (max-width:476px){
  body h1{
    font-size: 20px !important;
  }
  body h3, body h2{
    font-size: 22px !important;
  }
  body p{
    font-size: 14px !important;
  }
}
/* ::-webkit-scrollbar {
  width: 7px;
  background-color: #24c1dd;
}
::-webkit-scrollbar-thumb {
  background: #24c1dd;
}
::-webkit-scrollbar-track {
  background-color: #e9edf0;
  border-left: 1px solid #ededed;
} */
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html{
  scroll-behavior: smooth;
}
.header__content-nav{
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}